import React, { Component } from 'react';
import Buscador from './componentes/Buscador';
import Resultado from './componentes/Resultado';

class App extends Component {
  state = {
    termino: '',
    imagenes: [],
    pagina: '',
  }

  scroll = () => {
    const elemento = document.querySelector('.jumbotron');
    elemento.scrollIntoView('smooth', 'start');
  }

  paginaAnterior = () => {
     // leer el state
    let pagina = this.state.pagina;
    // si la pagina es la primera, no hacer nada
    if (pagina === 1) return null;
    // Sumarle +1 a la pagina actual
    pagina -= 1;
    // colocar el cambio al state
    this.setState({
      pagina
    }, () => {
      this.consultarApi();
      this.scroll();
    });
    // console.log(pagina);
    }

  paginaSiguiente = () => {
    // leer el state
    let pagina = this.state.pagina;

    // Sumarle +1 a la pagina actual
    pagina += 1;
    // colocar el cambio al state
    this.setState({
      pagina
    }, () => {
      this.consultarApi();
      this.scroll();
    });
    // console.log(pagina);
  }

  datosBusqueda = (termino) => {
    this.setState({
      termino : termino,
      pagina : 1
    }, () => { this.consultarApi() });
  }

  consultarApi = () => {
    const termino = this.state.termino;
    const pagina = this.state.pagina;
    const url = `https://pixabay.com/api/?key=25108636-aa26a7a7fcbdb7f3854a8c974&q=${termino}&per_page=30&page=${pagina}`;
    // console.log(url);
    fetch(url)
      .then(respuesta => respuesta.json())
      .then(resultado => this.setState({imagenes:resultado.hits}));
  }

  render() {
    return (
      <div className="app container">
        <div className="jumbotron">
          <p className="lead text-center">Buscador de Imagenes</p>
          <Buscador
             datosBusqueda={this.datosBusqueda}
          />
        </div>
        <div className="row justify-content-center">
            <Resultado
              imagenes={this.state.imagenes}
              paginaAnterior={this.paginaAnterior}
              paginaSiguiente={this.paginaSiguiente}
            />
        </div>
      </div>
  );
  }
}

export default App;
